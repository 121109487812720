<template>
    <div style="margin:0 auto">
        <Modal v-model="showdetail" title="查看出库单" width="1350px">
            <rkdview v-if="this.viewindex !=null" v-bind:suppliers="this.detaildata" v-bind:cgdetail="this.tbldata[this.viewindex]" />
        </Modal>
        <Form ref="ycwjQueryForm" :model="formInline" :rules="ruleInline" label-position="right" :label-width="100" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="right" width="23%">
                        <FormItem  label="车辆型号:" prop="parentid" style="margin-bottom:5px;margin-top:10px">
                            <i-select v-model="formInline.parentid">
                                <i-option value=null key="0">请先选择车型</i-option>
                                <i-option v-if="modellist !=null" v-for="(item,index) in modellist" :value="item.id" :key="item.id">车型{{item.modelno}}</i-option>
                            </i-select>
                        </FormItem>
                    </td>
                    <td align="right" width="23%">
                        <FormItem  label="选择年份:" prop="madeyear" style="margin-bottom:5px;margin-top:10px">
                            <i-select v-model="formInline.madeyear">
                                <i-option value=null key="0">请选择年份</i-option>
                                <i-option v-if="modellist !=null" v-for="item in [yearnow()-1, yearnow()]" :value="item" :key="item">{{item}}年</i-option>
                            </i-select>
                        </FormItem>
                    </td>
                    <td align="left">
                        <div style="display: inline-flex;justify-items: center;padding-left: 20px;">
                            <Button type="primary" @click="handleQuery()" >查询</Button>    
                            <Button  @click="handleReset()" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">出库单</label>  
        </div> 
        <Table :columns="tblcolumns" :data="tbldata" stripe border style="margin-top: 10px;">
            <template slot-scope="{ row, index }" slot="title" >
                <div >
                    <p >{{row.madeyear+'年'+row.mademonth}}月出库单</p>
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <div  class="opbar">
                    <Button type="success" size="small" ghost @click="view(index)">查看</Button>       
                </div>
            </template>
        </Table>
    </div>
</template>
    
<script>
import CkdPrint from '@/views/business/workshop/checkdept/purchase/CkdPrint'
export default {
    name:'Ckd',
    data(){
        return {
            logoninfo: this._self.$root.$store.state.LogonInfo,
            companyid: this._self.$root.$store.state.LogonInfo.companyid,
            modellist:this._self.$root.$store.state.ModelListInfo,
            formInline:{
                parentid:null,
                madeyear:null
            },
            ruleInline: {
                parentid: [
                    { required: true, message: '请选择车型', trigger: 'blur' },
                ],
                madeyear:[
                    { required: true, message: '请选择年份', trigger: 'blur' },
                ],
            },
            tblcolumns: [
                {
                    type: 'index',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '出库单',
                    slot: 'title',
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center',
                }
                
            ],
            tbldata: [],
            detaildata:[],
            showdetail:false,
            viewindex:null
        }
    },
    mounted(){
        //this.handleQuery();
    },
    methods:{
        view(index){
            this.viewindex = index
            this.showdetail = true
        },
        getmodelnobyid(id){
            for(let i=0;i<this.modellist.length;i++){
                if(this.modellist[i].id == id){
                    return this.modellist[i].modelno
                }
            }
            return null
        },
        handleQuery(){

            if(this.formInline.parentid == null){
                this.$Message.error({content:'请先选择车型！',duration:3})
                return
            }
            if(this.formInline.madeyear == null){
                this.$Message.error({content:'请先选择年份！',duration:3})
                return
            }
            let postdata={
                companyid: this.companyid,
                modelno: this.getmodelnobyid(this.formInline.parentid),
                bikemodelid: this.formInline.parentid,
                madeyear: this.formInline.madeyear
            }

            this.$axios({
                method:'post',
                url:'/api/checkdept/doc/cgjh/list',
                data: postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.tbldata = res.data.data.cgdetailList
                    this.detaildata = res.data.data.suppliers
                    console.log(this.tbldata)
                    this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        handleReset(){
            this.handleQuery()
        },
        yearnow(){
            return new Date().getFullYear()
        }
    },
    components:{
        'rkdview':CkdPrint
    }
}
</script>
<style scoped>
</style>    