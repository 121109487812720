<!-- 合格证台账打印功能-->
<template>
<div style="margin: 5 auto;">
    <div style="margin:0 auto;float:right">
        <Button type="warning"  @click="previewdata">打印预览</Button>
        <Button type="primary" style="margin-left: 15px;" @click="printdata">直接打印</Button>
    </div>
    <div id="printdiv" style="margin:0 auto;margin-top:1px;width:1300px;height:920px;clear: both;">
        <table  style="width: 100%;height:5%;word-wrap: break-word; word-break: break-all;" >
            <tr style="font-size: 16px;">
                <td width="25%">
                </td>
                <td width="50%" style="text-align: center;">
                    <h2>零部件出库单</h2>
                </td>
                <td width="25%">
                </td>
            </tr>
            <tr style="font-size: 12px;">
                <td width="25%">
                    车型: {{cgdetail.modelno}}
                </td>
                <td width="50%" style="text-align: center;">
                </td>
                <td width="25%">
                    日期:{{getlastdate(cgdetail.madeyear,cgdetail.mademonth)}}
                </td>
            </tr>
        </table>
        <table id="tbl"  border="1px solid black" cellspacing="0"  style="margin:0 auto;width:100%;"  >
            <tr style="height: 30px;font-size: 16px;font-weight: bold;font-family: '楷体';padding-left: 4px;">
                <th align="left">序号</th>
                <th align="center" valign="center">名称</th>
                <th align="center" valign="center">规格型号</th>
                <th align="center" valign="center">数量</th>
                <th align="center" valign="center">备注</th>
            </tr>
            <tr v-for="(item,index) in suppliers"  style="height: 20px;font-size: 12px;font-family: '楷体';padding-left: 4px;">
                <td>{{index+1}}</td>
                <td>{{item.product}}</td>
                <td>{{item.productmodelno}}</td>
                <td>{{item.product.indexOf("电池") != -1? 0: cgdetail.count}}</td>
                <td></td>
            </tr>
        </table>
        <table  style="width: 100%;height: 5%;margin-top: 8px;word-wrap: break-word; word-break: break-all;" >
            <tr style="text-align: left;">
                <td width="33%">
                    生产签收: {{modelcfg.partoutsigner}}
                </td>
                <td width="33%" >
                    制单: {{modelcfg.fileapprover}}
                </td>
                <td width="33%">
                </td>
            </tr>
        </table>
    </div>
</div>
</template>
 <script>
    import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
    import * as sysTool from '@/zsjs/systemTool.js'
    export default {
        name:'CkdPrint',
        props:['suppliers','cgdetail'],
        data(){
            return {
                modelcfg:this._self.$root.$store.state.ModelCfg
            }
        },
        mounted(){
            console.log(this.suppliers)
            console.log(this.cgdetail)
        },
        methods:{
            getpreDate(maodate){
                return sysTool.getNextDate(maodate,-1)
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            getexpectdate(m){
                return new Date().getFullYear()+'-'+this.cgdetail.mademonth+'-22'
            },
            getpurchasedate(m){
                return new Date().getFullYear()+'-'+this.cgdetail.mademonth+'-15'
            },
            previewdata(){
                public_preview_with_dir("printdiv",1,2);
            },
            printdata(){
                public_print_with_dir("printdiv",1,2);
            },
            getlastdate(y,m){
                let ldate = new Date(y, m, 0)
                console.log(m)
                console.log(ldate)
              return  ldate.Format('yyyy-MM-dd');
            },
        },
        mounted(){
        },
        watch:{
            cgdetail(n){
                console.log(this.cgdetail)
            },
            suppliers (n){
                console.log(this.suppliers)
            },
            modelcfg (n){
                console.log(this.modelcfg)
            }
        }
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}

#tbl {
    
}

#tbl  th {
    font-size: 16px;
    font-weight: bold;
}
#tbl  td{
    font-size: 12px;
    font-family: "楷体";
    padding-left: 4px;
}
</style>